import api from '@/api/user'
import {setItem} from "@/helpers/persistanceStorage";

const module = 'user'

const state = {
  loading: false,
  submitting: false,
  user: null,
  errors: null,
  isLoggedIn: null
}

export const actionTypes = {
  getCode: `[${module}] getCode`,
  confirmCode: `[${module}] confirmCode`,
  getUser: `[${module}] getUser`,
}

export const mutationTypes = {
  getCodeStart: `[${module}] getCodeStart`,
  getCodeSuccess: `[${module}] getCodeSuccess`,
  getCodeFailure: `[${module}] getCodeFailure`,

  confirmCodeStart: `[${module}] confirmCodeStart`,
  confirmCodeSuccess: `[${module}] confirmCodeSuccess`,
  confirmCodeFailure: `[${module}] confirmCodeFailure`,

  getUserStart: `[${module}] getUserStart`,
  getUserSuccess: `[${module}] getUserSuccess`,
  getUserFailure: `[${module}] getUserFailure`,
}

const mutations = {
  [mutationTypes.getCodeStart](state) {
    state.submitting = true
    state.errors = null
  },
  [mutationTypes.getCodeSuccess](state) {
    state.submitting = false
    state.isLoggedIn = false
  },
  [mutationTypes.getCodeFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.confirmCodeStart](state) {
    state.submitting = true
    state.errors = null
  },
  [mutationTypes.confirmCodeSuccess](state, payload) {
    state.submitting = false
    state.user = payload
    state.isLoggedIn = true
  },
  [mutationTypes.confirmCodeFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.getUserStart](state) {
    state.loading = true
  },
  [mutationTypes.getUserSuccess](state, payload) {
    state.loading = false
    state.user = payload
    state.isLoggedIn = true
  },
  [mutationTypes.getUserFailure](state) {
    state.loading = false
    state.user = null
    state.isLoggedIn = false
  },
}

const actions = {
  [actionTypes.getCode](context, phone) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCodeStart)
      api
        .getCode(phone)
        .then((response) => {
          context.commit(mutationTypes.getCodeSuccess)
          resolve(response.data.status)
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getCodeFailure,
            result.response.data.errors
          )
        })
    })
  },

  [actionTypes.confirmCode](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.confirmCodeStart)
      api
        .confirmCode(credentials)
        .then((response) => {
          context.commit(mutationTypes.confirmCodeSuccess, response.data.user)
          setItem('accessToken', response.data.token)
          resolve(response.data.user)
        })
        .catch((result) => {
          context.commit(
            mutationTypes.confirmCodeFailure,
            result.response.data.errors
          )
        })
    })
  },

  async [actionTypes.getUser](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getUserStart)
      api
        .getUser()
        .then((response) => {
          context.commit(
            mutationTypes.getUserSuccess,
            response.data.user
          )
          resolve(response.data.user)
        })
        .catch((error) => {
          context.commit(mutationTypes.getUserFailure)
          setItem("accessToken", "")
          resolve(error)
        })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
