import axios from "@/api/axios"

const module = 'user'

const getCode = (phone) => {
  return axios.post(`/${module}/getCode`, phone)
}
const confirmCode = (credentials) => {
  return axios.post(`/${module}/confirmCode`, credentials)
}

const getUser = function getUser() {
  return axios.get(`/${module}/getUser`)
}

export default {
  getCode,
  confirmCode,
  getUser,
}
