import axios from "@/api/axios"

const module = 'paymentTypes'

const get = () => {
  return axios.get(`/${module}/get`)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}

const remove = (id) => {
  return axios.delete(`/${module}/remove/`+id)
}

const restore = (id) => {
  return axios.post(`/${module}/restore/`+id)
}


export default {
  get,
  save,
  remove,
  restore,
}
