var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header",attrs:{"id":"mainHeader"}},[_c('router-link',{staticClass:"logo_link font-ui",attrs:{"to":'/'}},[_c('img',{staticClass:"logo_img",attrs:{"src":require("@/assets/logo.png"),"alt":"Glass ERP"}}),_c('span',{staticClass:"logo_text"},[_vm._v("GlassERP")])]),_c('span',{staticClass:"navigate_toggle",on:{"click":_vm.mobileMenuToggle}},[_c('v-icon',{attrs:{"name":_vm.hamburgerIcon}})],1),_c('div',{staticClass:"navigate",class:_vm.mobileOpen},[_c('div',{staticClass:"main_menu"},[_c('ul',{staticClass:"list"},_vm._l((_vm.navigationMenu),function(element){return _c('router-link',{key:element.component,staticClass:"list_element font-ui",attrs:{"to":{name: element.component},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{staticClass:"list_link",attrs:{"href":href,"role":"link"},on:{"click":navigate}},[_vm._v(" "+_vm._s(element.title)+" ")])])]}}],null,true)})}),1)]),_c('div',{staticClass:"user_menu"},[_c('ul',{staticClass:"list"},[(_vm.user)?_c('li',{staticClass:"list_element font-ui"},[_c('a',{staticClass:"list_link",attrs:{"href":"#","role":"link"}},[_vm._v(_vm._s(_vm.user.e.fio_short))])]):_vm._e(),(_vm.user.role === 'admin' || _vm.user.role === 'manager')?_c('li',{staticClass:"list_element font-ui with_btn"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":true}},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-question"}},[_c('span',[_vm._v("База знаний")])])],1)],1):_vm._e(),(_vm.user.role === 'admin' || _vm.user.role === 'manager')?_c('li',{staticClass:"list_element font-ui with_btn"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":true}},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-bell"}},[_c('span',[_vm._v("Уведомления")])])],1)],1):_vm._e(),(_vm.user.role === 'admin' || _vm.user.role === 'manager')?_c('li',{staticClass:"list_element font-ui with_btn"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":true}},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-message"}},[_c('span',[_vm._v("Сообщения")])])],1)],1):_vm._e(),(_vm.user.role === 'admin')?_c('router-link',{staticClass:"list_element font-ui",attrs:{"to":{name: 'Settings'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{staticClass:"list_link",attrs:{"href":href,"role":"link"},on:{"click":navigate}},[_c('i',{staticClass:"el-icon-setting"}),_vm._v(" "),_c('span',[_vm._v("Настройки")])])])]}}],null,false,1798957821)}):_vm._e(),_c('li',{staticClass:"list_element font-ui"},[_c('a',{staticClass:"list_link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-switch-button"}),_vm._v(" "),_c('span',[_vm._v("Выход")])])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }