import api from '@/api/mounting'

const module = 'mountings'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  current: null,
  requestList: [],
  orderList: [],
  errors: null,
}

export const mutationTypes = {
  getAllStart: `[${module}] getAllStart`,
  getAllSuccess: `[${module}] getAllSuccess`,
  getAllFailure: `[${module}] getAllFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  getRequestMountingsStart: `[${module}] getRequestMountingsStart`,
  getRequestMountingsSuccess: `[${module}] getRequestMountingsSuccess`,
  getRequestMountingsFailure: `[${module}] getRequestMountingsFailure`,

  getOrderMountingsStart: `[${module}] getOrderMountingsStart`,
  getOrderMountingsSuccess: `[${module}] getOrderMountingsSuccess`,
  getOrderMountingsFailure: `[${module}] getOrderMountingsFailure`,

  saveMountingStart: `[${module}] saveStart`,
  saveMountingSuccess: `[${module}] saveSuccess`,
  saveMountingFailure: `[${module}] saveFailure`,

  clear: `[${module}] clear`,
}

export const actionTypes = {
  getAll: `[${module}] getAll`,
  getRequestMountings: `[${module}] getRequestMountings`,
  getOrderMountings: `[${module}] getOrderMountings`,
  saveMounting: `[${module}] saveMounting`,
  removeMounting: `[${module}] removeMounting`,
  restoreMounting: `[${module}] restoreMounting`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getAllStart](state) {
    state.loading = true
  },
  [mutationTypes.getAllSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
    state.size = payload.count
  },
  [mutationTypes.getAllFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getCurrentStart](state) {
    state.loading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.loading = false
    state.current = payload.data
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getRequestMountingsStart](state) {
    state.loading = true
  },
  [mutationTypes.getRequestMountingsSuccess](state, payload) {
    state.loading = false
    state.requestList = payload.data
  },
  [mutationTypes.getRequestMountingsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getOrderMountingsStart](state) {
    state.loading = true
  },
  [mutationTypes.getOrderMountingsSuccess](state, payload) {
    state.loading = false
    state.orderList = payload.data
  },
  [mutationTypes.getOrderMountingsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.saveMountingStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveMountingSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveMountingFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },
  [mutationTypes.clear](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.size = 0
    state.current = null
    state.requestList = []
    state.orderList = []
    state.errors = null
  },
}

const actions = {
  [actionTypes.getAll](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getAllSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getAllFailure, errors)
        })
    })
  },
  [actionTypes.getRequestMountings](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRequestMountingsStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getRequestMountingsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getRequestMountingsFailure, errors)
        })
    })
  },
  [actionTypes.getOrderMountings](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getOrderMountingsStart)
      api.getByOrderId(params)
        .then((response) => {
          context.commit(mutationTypes.getOrderMountingsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getOrderMountingsFailure, errors)
        })
    })
  },
  [actionTypes.saveMounting](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveMountingStart)
      api.save(data)
      .then((response) => {
          context.commit(mutationTypes.saveMountingSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveMountingFailure, errors)
        })
    })
  },
  [actionTypes.removeMounting](context, params) {
    return new Promise((resolve) => {
      api.remove(params)
    })
  },
  [actionTypes.restoreMounting](context, params) {
    return new Promise((resolve) => {
      api.restore(params)
    })
  },
  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clear)
    })
  },
}

export default {
  state,
  mutations,
  actions
}
