import axios from "@/api/axios"

const module = 'payments'

const getAll = (filter) => {
  return axios.get(`/${module}/get`, {params: filter})
}

const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/` + id)
}

const getByOrderId = (id) => {
  return axios.get(`/${module}/getByOrderId/` + id)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}


const remove = (id) => {
  return axios.delete(`/${module}/remove/` + id)
}

const restore = (id) => {
  return axios.post(`/${module}/restore/` + id)
}


export default {
  getAll,
  getByRequestId,
  getByOrderId,
  save,
  remove,
  restore,
}
