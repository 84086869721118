import api from '@/api/order'

const module = 'orders'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  requestList: [],
  requestListLoading: false,
  current: null,
  currentLoading: false,
  currentSubmitting: false,
  errors: null,
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,

  getRequestListStart: `[${module}] getRequestListStart`,
  getRequestListSuccess: `[${module}] getRequestListSuccess`,
  getRequestListFailure: `[${module}] getRequestListFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  saveCurrentStart: `[${module}] saveCurrentStart`,
  saveCurrentSuccess: `[${module}] saveCurrentSuccess`,
  saveCurrentFailure: `[${module}] saveCurrentFailure`,

  clearData: `[${module}] clearData`,
  clearCurrent: `[${module}] clearCurrent`,
}

export const actionTypes = {
  get: `[${module}] get`,
  getByRequestId: `[${module}] getByRequestId`,
  save: `[${module}] save`,
  remove: `[${module}] remove`,

  getCurrent: `[${module}] getCurrent`,
  saveCurrent: `[${module}] saveCurrent`,

  create: `[${module}] create`,

  clear: `[${module}] clear`,

  setPositionPrice: `[${module}] setPositionPrice`,
  copy: `[${module}] copy`,
  addPosition: `[${module}] addPosition`,
  getPositions: `[${module}] getPositions`,
  updatePosition: `[${module}] updatePosition`,
  deletePosition: `[${module}] deletePosition`,
  setDiscount: `[${module}] setDiscount`,
  reorder: `[${module}] reorder`,

  delete: `[${module}] delete`,
  updateCurrent: `[${module}] updateCurrent`,
  clearCurrent: `[${module}] clearCurrent`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.list = payload.orders
    state.size = payload.size
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.getCurrentStart](state) {
    //state.currentLoading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.currentLoading = false
    state.current = payload.order
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.currentLoading = false
    state.errors = payload
  },

  [mutationTypes.getRequestListStart](state) {
    state.requestListLoading = true
  },
  [mutationTypes.getRequestListSuccess](state, payload) {
    state.requestListLoading = false
    state.requestList = payload.orders
  },
  [mutationTypes.getRequestListFailure](state, payload) {
    state.requestListLoading = false
    state.errors = payload
  },

  [mutationTypes.saveCurrentStart](state) {
    state.currentSubmitting = true
  },
  [mutationTypes.saveCurrentSuccess](state) {
    state.currentSubmitting = false
  },
  [mutationTypes.saveCurrentFailure](state, payload) {
    state.currentSubmitting = false
    state.errors = payload
  },

  [mutationTypes.clearData](state) {
    state.list = null
    state.requestList = []
    state.requestListLoading = false
    state.loading = false
    state.submitting = false
    state.current = null
    state.currentLoading = false
    state.currentSubmitting = false
    state.errors = null
    state.size = 0
  },

  [mutationTypes.clearCurrent](state) {
    state.current = null
    state.currentLoading = false
    state.currentSubmitting = false
  }
}

const actions = {
  [actionTypes.get](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.getByRequestId](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRequestListStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getRequestListSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getRequestListFailure, errors)
        })
    })
  },

  [actionTypes.getCurrent](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getById(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getCurrentFailure, errors)
        })
    })
  },

  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },
  [actionTypes.copy](context, data) {
    return new Promise((resolve) => {
      api.copy(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.create](context, data) {
    return new Promise((resolve) => {
      api.create(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((errors) => {
          console.log('error on create order')
        })
    })
  },
  [actionTypes.addPosition](context, data) {
    return new Promise((resolve) => {
      api.addPosition(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {

        })
    })
  },

  [actionTypes.updatePosition](context, data) {
    return new Promise((resolve) => {
      api.updatePosition(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.deletePosition](context, params) {
    return new Promise((resolve) => {
      api.deletePositionById(params).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clearData)
    })
  },
  [actionTypes.delete](context, id) {
    return new Promise((resolve) => {
      api.deleteOrder(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.setPositionPrice](context, data) {
    return new Promise((resolve) => {
      api.setPositionPrice(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.setDiscount](context, data) {
    return new Promise((resolve) => {
      api.setDiscount(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  [actionTypes.reorder](context, data) {
    return new Promise((resolve) => {
      api.reorder(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  [actionTypes.clearCurrent](context) {
    return new Promise((resolve) => {
       context.commit(mutationTypes.clearCurrent)
    })
  },
}

export default {
  state,
  mutations,
  actions,
}
