import api from '@/api/document'

const module = 'documentTemplates'

const state = {
  loading: false,
  submitting: false,
  templates: [],
  errors: null,
}

export const mutationTypes = {
  getTemplatesStart: `[${module}] getTemplatesStart`,
  getTemplatesSuccess: `[${module}] getTemplatesSuccess`,
  getTemplatesFailure: `[${module}] getTemplatesFailure`,

  saveTemplateStart: `[${module}] saveTemplateStart`,
  saveTemplateSuccess: `[${module}] saveTemplateSuccess`,
  saveTemplateFailure: `[${module}] saveTemplateFailure`,
}

export const actionTypes = {
  getTemplates: `[${module}] getTemplates`,
  saveTemplate: `[${module}] saveTemplate`,
  removeTemplate: `[${module}] removeTemplate`,
  restoreTemplate: `[${module}] restoreTemplate`,
}


const mutations = {
  [mutationTypes.getTemplatesStart](state) {
    state.loading = true
  },
  [mutationTypes.getTemplatesSuccess](state, payload) {
    state.loading = false
    state.templates = payload.data
  },
  [mutationTypes.getTemplatesFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveTemplateStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveTemplateSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveTemplateFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  }
}


const actions = {
  [actionTypes.getTemplates](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTemplatesStart)
      api.getTemplates(params)
        .then((response) => {
          context.commit(mutationTypes.getTemplatesSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getTemplatesFailure, errors)
        })
    })
  },

  [actionTypes.saveTemplate](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveTemplateStart)
      api.saveTemplate(data)
        .then((response) => {
          context.commit(mutationTypes.saveTemplateSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveTemplateFailure, errors)
        })
    })
  },

  [actionTypes.removeTemplate](context, id) {
    return new Promise((resolve) => {
      api.removeTemplate(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.restoreTemplate](context, id) {
    return new Promise((resolve) => {
      api.restoreTemplate(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  }
}

export default {
  state,
  mutations,
  actions
}
