import api from '@/api/request'

const module = 'requests'

const state = {
  list: null,
  loading: false,
  submitting: false,
  current: null,
  currentLoading: false,
  currentSubmitting: false,
  errors: null,
  size: 0
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  saveCurrentStart: `[${module}] saveCurrentStart`,
  saveCurrentSuccess: `[${module}] saveCurrentSuccess`,
  saveCurrentFailure: `[${module}] saveCurrentFailure`,

  clearData: `[${module}] clearData`,
}

export const actionTypes = {
  get: `[${module}] get`,
  getAll: `[${module}] getAll`,
  save: `[${module}] save`,
  remove: `[${module}] remove`,

  getCurrent: `[${module}] getCurrent`,
  saveCurrent: `[${module}] saveCurrent`,

  createRequest: `[${module}] createRequest`,

  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.list = payload.requests
    state.size = payload.size
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.getCurrentStart](state) {
    state.currentLoading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.currentLoading = false
    state.current = payload.request
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.currentLoading = false
    state.errors = payload
  },

  [mutationTypes.saveCurrentStart](state) {
    state.currentSubmitting = true
  },
  [mutationTypes.saveCurrentSuccess](state) {
    state.currentSubmitting = false
  },
  [mutationTypes.saveCurrentFailure](state, payload) {
    state.currentSubmitting = false
    state.errors = payload
  },

  [mutationTypes.clearData](state) {
    state.list = null
    state.loading = false
    state.submitting = false
    state.errors = null
    state.size = 0
  }
}

const actions = {

  [actionTypes.getAll](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getAll()
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.get](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.getCurrent](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getById(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getCurrentFailure, errors)
        })
    })
  },

  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },

  [actionTypes.saveCurrent](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveCurrentStart)
      api.saveRequest(data)
        .then((response) => {
          context.commit(mutationTypes.saveCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveCurrentFailure, errors)
        })
    })
  },

  [actionTypes.createRequest](context, data) {
    return new Promise((resolve) => {
      api.createRequest(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((errors) => {
          console.log('error on create request')
        })
    })
  },

  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clearData)
    })
  },
  [actionTypes.remove](context, id) {
    return new Promise((resolve) => {
      api.remove(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  },
}

export default {
  state,
  mutations,
  actions,
}
