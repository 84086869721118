import api from '@/api/payment'

const module = 'payments'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  current: null,
  requestList: [],
  orderList: [],
  errors: null,
}

export const mutationTypes = {
  getAllStart: `[${module}] getAllStart`,
  getAllSuccess: `[${module}] getAllSuccess`,
  getAllFailure: `[${module}] getAllFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  getRequestPaymentsStart: `[${module}] getRequestPaymentsStart`,
  getRequestPaymentsSuccess: `[${module}] getRequestPaymentsSuccess`,
  getRequestPaymentsFailure: `[${module}] getRequestPaymentsFailure`,

  getOrderPaymentsStart: `[${module}] getOrderPaymentsStart`,
  getOrderPaymentsSuccess: `[${module}] getOrderPaymentsSuccess`,
  getOrderPaymentsFailure: `[${module}] getOrderPaymentsFailure`,

  savePaymentStart: `[${module}] saveStart`,
  savePaymentSuccess: `[${module}] saveSuccess`,
  savePaymentFailure: `[${module}] saveFailure`,

  clear: `[${module}] clear`,
}

export const actionTypes = {
  getAll: `[${module}] getAll`,
  getRequestPayments: `[${module}] getRequestPayments`,
  getOrderPayments: `[${module}] getOrderPayments`,
  savePayment: `[${module}] savePayment`,
  removePayment: `[${module}] removePayment`,
  restorePayment: `[${module}] restorePayment`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getAllStart](state) {
    state.loading = true
  },
  [mutationTypes.getAllSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
    state.size = payload.count
  },
  [mutationTypes.getAllFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getCurrentStart](state) {
    state.loading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.loading = false
    state.current = payload.data
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getRequestPaymentsStart](state) {
    state.loading = true
  },
  [mutationTypes.getRequestPaymentsSuccess](state, payload) {
    state.loading = false
    state.requestList = payload.data
  },
  [mutationTypes.getRequestPaymentsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getOrderPaymentsStart](state) {
    state.loading = true
  },
  [mutationTypes.getOrderPaymentsSuccess](state, payload) {
    state.loading = false
    state.orderList = payload.data
  },
  [mutationTypes.getOrderPaymentsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.savePaymentStart](state) {
    state.submitting = true
  },
  [mutationTypes.savePaymentSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.savePaymentFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },
  [mutationTypes.clear](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.size = 0
    state.current = null
    state.requestList = []
    state.orderList = []
    state.errors = null
  },
}

const actions = {
  [actionTypes.getAll](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllStart)
      api.getAll(params)
        .then((response) => {
          context.commit(mutationTypes.getAllSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getAllFailure, errors)
        })
    })
  },
  [actionTypes.getRequestPayments](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRequestPaymentsStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getRequestPaymentsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getRequestPaymentsFailure, errors)
        })
    })
  },
  [actionTypes.getOrderPayments](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getOrderPaymentsStart)
      api.getByOrderId(params)
        .then((response) => {
          context.commit(mutationTypes.getOrderPaymentsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getOrderPaymentsFailure, errors)
        })
    })
  },
  [actionTypes.savePayment](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.savePaymentStart)
      api.save(data)
      .then((response) => {
          context.commit(mutationTypes.savePaymentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.savePaymentFailure, errors)
        })
    })
  },
  [actionTypes.removePayment](context, params) {
    return new Promise((resolve) => {
      api.remove(params)
    })
  },
  [actionTypes.restorePayment](context, params) {
    return new Promise((resolve) => {
      api.restore(params)
    })
  },
  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clear)
    })
  },
}

export default {
  state,
  mutations,
  actions
}
