<template>
  <header class="main-header" id="mainHeader">
    <router-link :to="'/'" class="logo_link font-ui">
      <img src="@/assets/logo.png" alt="Glass ERP" class="logo_img">
      <span class="logo_text">GlassERP</span>
    </router-link>
    <span class="navigate_toggle" @click="mobileMenuToggle">
      <v-icon :name="hamburgerIcon"/>
    </span>
    <div class="navigate" :class="mobileOpen">
      <div class="main_menu">
        <ul class="list">
          <router-link
              v-for="element in navigationMenu"
              :key="element.component"
              :to="{name: element.component}"
              custom
              v-slot="{href, navigate, isActive}"
              class="list_element font-ui">
            <li :class="[isActive && 'active']">
              <a :href="href" class="list_link" @click="navigate" role="link">
                {{ element.title }}
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="user_menu">
        <ul class="list">
          <li class="list_element font-ui" v-if="user">
            <a href="#" class="list_link" role="link">{{ user.e.fio_short }}</a>
          </li>
          <li class="list_element font-ui with_btn" v-if="user.role === 'admin' || user.role === 'manager'">
            <el-badge :is-dot="true" class="item">
              <el-button size="mini" icon="el-icon-question"><span>База знаний</span></el-button>
            </el-badge>
          </li>
          <li class="list_element font-ui with_btn" v-if="user.role === 'admin' || user.role === 'manager'">
            <el-badge :is-dot="true" class="item">
              <el-button size="mini" icon="el-icon-bell"><span>Уведомления</span></el-button>
            </el-badge>
          </li>
          <li class="list_element font-ui with_btn" v-if="user.role === 'admin' || user.role === 'manager'">
            <el-badge :is-dot="true" class="item">
              <el-button size="mini" icon="el-icon-message"><span>Сообщения</span></el-button>
            </el-badge>
          </li>
          <router-link
              v-if="user.role === 'admin'"
              :to="{name: 'Settings'}"
              custom
              v-slot="{href, navigate, isActive}"
              class="list_element font-ui">
            <li :class="[isActive && 'active']">
              <a :href="href" class="list_link" @click="navigate" role="link">
                <i class="el-icon-setting"></i> <span>Настройки</span>
              </a>
            </li>
          </router-link>
          <li class="list_element font-ui">
            <a href="#" class="list_link" @click.prevent="logout">
              <i class="el-icon-switch-button"></i> <span>Выход</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import {mapState} from "vuex"
import {setItem} from '@/helpers/persistanceStorage'
import {actionTypes} from '@/store/modules/user'

export default {
  name: "MainHeader",
  data() {
    return {
      menu: {
        admin: [
          {component: "Requests", title: "Обращения"},
          {component: "Orders", title: "Заказы"},
          {component: "Tasks", title: "Задачи"},
          {component: "Calendar", title: "Замеры и монтаж"},
          {component: "Clients", title: "Клиенты"},
          {component: "Purchases", title: "Закупки"},
          {component: "Assortment", title: "Товары и услуги"},
          {component: "Payments", title: "Платежи"},
        ],
        manager: [
          {component: "Requests", title: "Обращения"},
          {component: "Orders", title: "Заказы"},
          {component: "Tasks", title: "Задачи"},
          {component: "Calendar", title: "Замеры и монтаж"},
          {component: "Clients", title: "Клиенты"},
          {component: "Purchases", title: "Закупки"},
          {component: "Assortment", title: "Товары и услуги"},
          {component: "Payments", title: "Платежи"},
        ],
        stockman: [
          {component: "StockOrders", title: "Заказы"},
          {component: "Assortment", title: "Товары"},
        ],
        fitter: [
          {component: "FitterList", title: "Заявки"},
          {component: "FitterWork", title: "В работе"},
          {component: "FitterComplete", title: "Выполненные"},
        ],
      },
      mobileOpen: "",
      hamburgerIcon: 'bars'
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    navigationMenu() {
      if (this.user.role === 'admin') {
        return this.menu.admin
      }
      if (this.user.role === 'manager') {
        return this.menu.manager
      }
      if (this.user.role === 'stockman') {
        return this.menu.stockman
      }
      if (this.user.role === 'fitter') {
        return this.menu.fitter
      }
    }
  },
  methods: {
    createHandler(c) {
      if (c === 'request') {
        this.$emit('newRequest')
      } else if (c === 'payment') {
        this.$emit('newPayment')
      } else if (c === 'task') {
        this.$emit('newTask')
      } else if (c === 'client') {
        this.$emit('newClient')
      } else if (c === 'product') {
        this.$emit('newProduct')
      } else if (c === 'service') {
        this.$emit('newService')
      }
    },
    mobileMenuToggle() {
      if (this.hamburgerIcon === 'bars') {
        this.hamburgerIcon = 'times'
        this.mobileOpen = 'open'
      } else {
        this.mobileMenuClose()
      }
    },
    mobileMenuClose() {
      this.hamburgerIcon = 'bars'
      this.mobileOpen = ''
    },
    logout() {
      setItem("accessToken", "")
      this.$store.dispatch(actionTypes.getUser)
      this.$router.push({name: 'Auth'})
    }
  },
  watch: {
    $route() {
      this.mobileMenuClose()
    }
  },
}
</script>
