import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

import _ from 'lodash'
import moment from 'moment'
import InputFacade from 'vue-input-facade'

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#erp')

Vue.use(InputFacade)
moment.locale('ru')
Vue.prototype.moment = moment
Vue.prototype._ = _
Vue.component('v-icon', Icon)
