import axios from "@/api/axios"

const module = 'clients'

const getAll = (filter) => {
  return axios.get(`/${module}/get`, {params: filter})
}

const getById = (id) => {
  return axios.get(`/${module}/getById/` + id)
}

const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/` + id)
}

const getByOrderId = (id) => {
  return axios.get(`/${module}/getByOrderId/` + id)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}

const remove = (id) => {
  return axios.delete(`/${module}/remove/` + id)
}

const restore = (id) => {
  return axios.post(`/${module}/restore/` + id)
}


const addContact = (data) => {
  return axios.post(`/${module}/addContact`, data)
}

const addAddress = (data) => {
  return axios.post(`/${module}/addAddress`, data)
}

const addCounterparty = (data) => {
  return axios.post(`/${module}/addCounterparty`, data)
}

const deleteAddress = (params) => {
  return axios.get(`/${module}/deleteAddress`, {params: params})
}
const deleteContact = (params) => {
  return axios.get(`/${module}/deleteContact`, {params: params})
}

const deleteCounterparty = (params) => {
  return axios.get(`/${module}/deleteCounterparty`, {params: params})
}


export default {
  getAll,
  getById,
  getByRequestId,
  getByOrderId,
  save,
  remove,
  restore,
  addContact,
  addAddress,
  addCounterparty,
  deleteAddress,
  deleteContact,
  deleteCounterparty,
}
