import api from '@/api/assessment'

const module = 'assessments'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  current: null,
  requestList: [],
  orderList: [],
  errors: null,
}

export const mutationTypes = {
  getAllStart: `[${module}] getAllStart`,
  getAllSuccess: `[${module}] getAllSuccess`,
  getAllFailure: `[${module}] getAllFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  getRequestAssessmentsStart: `[${module}] getRequestAssessmentsStart`,
  getRequestAssessmentsSuccess: `[${module}] getRequestAssessmentsSuccess`,
  getRequestAssessmentsFailure: `[${module}] getRequestAssessmentsFailure`,

  getOrderAssessmentsStart: `[${module}] getOrderAssessmentsStart`,
  getOrderAssessmentsSuccess: `[${module}] getOrderAssessmentsSuccess`,
  getOrderAssessmentsFailure: `[${module}] getOrderAssessmentsFailure`,

  saveAssessmentStart: `[${module}] saveStart`,
  saveAssessmentSuccess: `[${module}] saveSuccess`,
  saveAssessmentFailure: `[${module}] saveFailure`,

  clear: `[${module}] clear`,
}

export const actionTypes = {
  getAll: `[${module}] getAll`,
  getRequestAssessments: `[${module}] getRequestAssessments`,
  getOrderAssessments: `[${module}] getOrderAssessments`,
  saveAssessment: `[${module}] saveAssessment`,
  removeAssessment: `[${module}] removeAssessment`,
  restoreAssessment: `[${module}] restoreAssessment`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getAllStart](state) {
    state.loading = true
  },
  [mutationTypes.getAllSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
    state.size = payload.count
  },
  [mutationTypes.getAllFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getCurrentStart](state) {
    state.loading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.loading = false
    state.current = payload.data
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getRequestAssessmentsStart](state) {
    state.loading = true
  },
  [mutationTypes.getRequestAssessmentsSuccess](state, payload) {
    state.loading = false
    state.requestList = payload.data
  },
  [mutationTypes.getRequestAssessmentsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getOrderAssessmentsStart](state) {
    state.loading = true
  },
  [mutationTypes.getOrderAssessmentsSuccess](state, payload) {
    state.loading = false
    state.orderList = payload.data
  },
  [mutationTypes.getOrderAssessmentsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.saveAssessmentStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveAssessmentSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveAssessmentFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },
  [mutationTypes.clear](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.size = 0
    state.current = null
    state.requestList = []
    state.orderList = []
    state.errors = null
  },
}

const actions = {
  [actionTypes.getAll](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getAllSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getAllFailure, errors)
        })
    })
  },
  [actionTypes.getRequestAssessments](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRequestAssessmentsStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getRequestAssessmentsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getRequestAssessmentsFailure, errors)
        })
    })
  },
  [actionTypes.getOrderAssessments](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getOrderAssessmentsStart)
      api.getByOrderId(params)
        .then((response) => {
          context.commit(mutationTypes.getOrderAssessmentsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getOrderAssessmentsFailure, errors)
        })
    })
  },
  [actionTypes.saveAssessment](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveAssessmentStart)
      api.save(data)
      .then((response) => {
          context.commit(mutationTypes.saveAssessmentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveAssessmentFailure, errors)
        })
    })
  },
  [actionTypes.removeAssessment](context, params) {
    return new Promise((resolve) => {
      api.remove(params)
    })
  },
  [actionTypes.restoreAssessment](context, params) {
    return new Promise((resolve) => {
      api.restore(params)
    })
  },
  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clear)
    })
  },
}

export default {
  state,
  mutations,
  actions
}
