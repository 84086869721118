import axios from "@/api/axios"

const module = 'calendar'

const get = (params) => {
  return axios.get(`/${module}/getAll`, {params: params})
}

const getById = (id) => {
  return axios.get(`/${module}/getById/`+id)
}

const getFiltered = (filter) => {
  return axios.get(`/${module}/getFiltered`, {params: filter})
}

const getFitterList = (filter) => {
  return axios.get(`/${module}/getFitterList`, {params: filter})
}

const getByOrderId = (id) => {
  return axios.get(`/${module}/getByOrderId/`+id)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}

const create = data => {
  return axios.post(`/${module}/create`, data)
}

const changeStatus = (data) => {
  return axios.post(`/${module}/changeStatus`, data)
}


const remove = (id) => {
  return axios.delete(`/${module}/remove/`+id)
}

const restore = (id) => {
  return axios.post(`/${module}/restore/`+id)
}


export default {
  get,
  getFitterList,
  save,
  remove,
  restore,
  getFiltered,
  getById,
  changeStatus,
  create
}
