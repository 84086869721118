import axios from 'axios'
import {getItem} from "@/helpers/persistanceStorage"

axios.defaults.baseURL = 'https://online.lbsteklo.ru/v21/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(config => {
  const token = getItem('accessToken')
  config.headers.Authorization = token ? `Token ${token}` : ''
  return config
})


export default axios
