import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from "@/store/modules/user";

import document_templates from "@/store/modules/documents/templates";

import statuses_for_orders from "@/store/modules/statuses/order"
import statuses_for_requests from "@/store/modules/statuses/request"

import brigades from "@/store/modules/brigades";
import employees from "@/store/modules/employees";
import expenses from "@/store/modules/expenses";
import offices from "@/store/modules/offices";
import organizations from "@/store/modules/organizations";
import storages from "@/store/modules/storages";
import suppliers from "@/store/modules/suppliers";
import paymentTypes from "@/store/modules/paymentTypes";

import payments from "@/store/modules/payments";
import assortment from "@/store/modules/assortment";
import clients from "@/store/modules/clients";
import orders from "@/store/modules/orders"
import requests from "@/store/modules/requests";
import calculations from "@/store/modules/calculations";

import timeline from "@/store/modules/timeline";
import mountings from "@/store/modules/mountings";
import assessments from "@/store/modules/assessments";
import calendar from "@/store/modules/calendar";
import files from "@/store/modules/files";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    document_templates,
    statuses_for_requests,
    statuses_for_orders,
    brigades,
    employees,
    expenses,
    offices,
    organizations,
    storages,
    suppliers,
    payments,
    assortment,
    clients,
    orders,
    requests,
    calculations,
    timeline,
    mountings,
    assessments,
    paymentTypes,
    files,
    calendar
  }
})
