import api from '@/api/timeline'

const module = 'timeline'

const state = {
  loading: false,
  submitting: false,
  elements: null,
  errors: null,
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  clearData: `[${module}] clearData`,
}


export const actionTypes = {
  clear: `[${module}] clear`,
  getByRequestId: `[${module}] getByRequestId`,
  getByOrderId: `[${module}] getByOrderId`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.elements = payload.timeline
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.clearData](state) {
    state.submitting = false
    state.loading = false
    state.errors = null
    state.elements = null
  },

}

const actions = {
  [actionTypes.getByRequestId](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getByRequestId(id)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.getByOrderId](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getByOrderId(id)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clearData)
    })
  },
}



export default {
  state,
  mutations,
  actions
}
