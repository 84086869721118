import axios from '@/api/axios'

const module = 'assortment'

const getProducts = (filter) => {
  return axios.get(`${module}/getProducts`, {params: filter})
}

const saveProduct = (data) => {
  return axios.post(`/${module}/save`, data)
}

const removeProduct = (id) => {
  return axios.delete(`/${module}/removeProduct/`+id)
}

const getGroups = () => {
  return axios.get(`/${module}/getGroups`)
}

const saveGroup = (data) => {
  return axios.post(`/${module}/saveGroup`, data)
}

const removeGroup = (id) => {
  return axios.delete(`/${module}/removeGroup/`+id)
}

const removeImage = (id) => {
  return axios.delete(`/${module}/removeImage/`+id)
}

export default {
  getProducts,
  saveProduct,
  removeProduct,
  getGroups,
  saveGroup,
  removeGroup,
  removeImage,
}
