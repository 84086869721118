import api from '@/api/file'

const module = 'files'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  errors: null,
}

export const mutationTypes = {
  getRequestFilesStart: `[${module}] getRequestFilesStart`,
  getRequestFilesSuccess: `[${module}] getRequestFilesSuccess`,
  getRequestFilesFailure: `[${module}] getRequestFilesFailure`,

  getOrderFilesStart: `[${module}] getOrderFilesStart`,
  getOrderFilesSuccess: `[${module}] getOrderFilesSuccess`,
  getOrderFilesFailure: `[${module}] getOrderFilesFailure`,

  saveFileStart: `[${module}] saveStart`,
  saveFileSuccess: `[${module}] saveSuccess`,
  saveFileFailure: `[${module}] saveFailure`,

  clear: `[${module}] clear`,
}

export const actionTypes = {
  getRequestFiles: `[${module}] getRequestFiles`,
  getOrderFiles: `[${module}] getOrderFiles`,
  saveFile: `[${module}] saveFile`,
  removeFile: `[${module}] removeFile`,
  restoreFile: `[${module}] restoreFile`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getRequestFilesStart](state) {
    state.loading = true
  },
  [mutationTypes.getRequestFilesSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
  },
  [mutationTypes.getRequestFilesFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getOrderFilesStart](state) {
    state.loading = true
  },
  [mutationTypes.getOrderFilesSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
  },
  [mutationTypes.getOrderFilesFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.saveFileStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveFileSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFileFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },
  [mutationTypes.clear](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.size = 0
    state.errors = null
  },
}

const actions = {
  [actionTypes.getRequestFiles](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRequestFilesStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getRequestFilesSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getRequestFilesFailure, errors)
        })
    })
  },
  [actionTypes.getOrderFiles](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getOrderFilesStart)
      api.getByOrderId(params)
        .then((response) => {
          context.commit(mutationTypes.getOrderFilesSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getOrderFilesFailure, errors)
        })
    })
  },
  [actionTypes.saveFile](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveFileStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveFileSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFileFailure, errors)
        })
    })
  },
  [actionTypes.removeFile](context, params) {
    return new Promise((resolve) => {
      api.remove(params).then((response) => {
        resolve(response)
      })
    })
  },
  [actionTypes.restoreFile](context, params) {
    return new Promise((resolve) => {
      api.restore(params)
    })
  },
  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clear)
    })
  },
}

export default {
  state,
  mutations,
  actions
}
