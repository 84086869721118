import axios from "@/api/axios"

const module = 'requests'

const get = (filter) => {
  return axios.get(`/${module}/get`, {params: filter})
}

const getAll = () => {
  return axios.get(`/${module}/getAll`)
}

const getComplexById = (id) => {
  return axios.get(`/${module}/getComplexById/` + id)
}

const getById = (id) => {
  return axios.get(`/${module}/getById/` + id)
}

const getOthersByRequestId = (params) => {
  return axios.get(`/${module}/getOthersByRequestId`, {params: params})
}

const saveRequest = (data) => {
  return axios.post(`/${module}/saveRequest`, data)
}

const createRequest = (data) => {
  return axios.post('/requests/create', data)
}

export default {
  get,
  getComplexById,
  getById,
  getOthersByRequestId,
  saveRequest,
  createRequest,
  getAll,
}
