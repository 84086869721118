<template>
  <div class="main-layout">
    <main-header></main-header>
    <div class="content-container">
      <router-view/>
    </div>
    <!--
      TODO: Боксы для уведомлений / сообщений / звонилки
    -->
  </div>
</template>

<script>
import MainHeader from "@/components/header/MainHeader";

import {actionTypes as employeeActions} from '@/store/modules/employees'
import {actionTypes as brigadeActions} from '@/store/modules/brigades'
import {actionTypes as organizationActions} from '@/store/modules/organizations'
import {actionTypes as officeActions} from '@/store/modules/offices'
import {actionTypes as storageActions} from '@/store/modules/storages'
import {actionTypes as statusRequestActions} from '@/store/modules/statuses/request'
import {actionTypes as statusOrderActions} from '@/store/modules/statuses/order'
import {actionTypes as supplierActions} from '@/store/modules/suppliers'
import {actionTypes as expenseActions} from '@/store/modules/expenses'
import {actionTypes as documentTemplateActions} from '@/store/modules/documents/templates'
import {actionTypes as paymentTypeActions} from "@/store/modules/paymentTypes";

export default {
  name: "MainLayout",
  components: {
    MainHeader
  },
  data(){
    return {

    }
  },
  computed: {

  },
  methods: {
    loadData(){
      this.$store.dispatch(employeeActions.get)
      this.$store.dispatch(brigadeActions.get)
      this.$store.dispatch(organizationActions.get)
      this.$store.dispatch(officeActions.get)
      this.$store.dispatch(storageActions.get)
      this.$store.dispatch(statusOrderActions.get)
      this.$store.dispatch(statusRequestActions.get)
      this.$store.dispatch(supplierActions.get)
      this.$store.dispatch(expenseActions.get)
      this.$store.dispatch(documentTemplateActions.getTemplates)
      this.$store.dispatch(paymentTypeActions.get)
    }
  },
  created(){
    this.loadData()
  }
}
</script>

<style lang="scss">
@import "../styles/general";
</style>
