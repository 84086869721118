import api from '@/api/client'

const module = 'clients'

const state = {
  loading: false,
  submitting: false,
  list: [],
  size: 0,
  current: null,
  currentLoading: false,
  currentSubmitting: false,
  errors: null,
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  clearData: `[${module}] clearData`,
}

export const actionTypes = {
  get: `[${module}] get`,
  save: `[${module}] save`,
  clear: `[${module}] clear`,

  getByRequestId: `[${module}] getByRequestId`,
  getByOrderId: `[${module}] getByOrderId`,

  addContact: `[${module}] addContact`,
  addAddress: `[${module}] addAddress`,
  addCounterparty: `[${module}] addCounterparty`,

  deleteContact: `[${module}] deleteContact`,
  deleteAddress: `[${module}] deleteAddress`,
  deleteCounterparty: `[${module}] deleteCounterparty`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
    state.size = payload.count
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.getCurrentStart](state) {
    state.currentLoading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.currentLoading = false
    state.current = payload
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.currentLoading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.clearData](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.size = 0
    state.current = null
    state.currentLoading = false
    state.currentSubmitting = false
    state.errors = null
  },
}

const actions = {
  [actionTypes.get](context, filter) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getAll(filter)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.getByRequestId](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getCurrentFailure, errors)
        })
    })
  },

  [actionTypes.getByOrderId](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getByOrderId(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getCurrentFailure, errors)
        })
    })
  },

  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },

  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clearData)
    })
  },

  [actionTypes.addContact](context, data) {
    return new Promise((resolve) => {
      api.addContact(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.addAddress](context, data) {
    return new Promise((resolve) => {
      api.addAddress(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.addCounterparty](context, data) {
    return new Promise((resolve) => {
      api.addCounterparty(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.deleteAddress](context, data) {
    return new Promise((resolve) => {
      api.deleteAddress(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.deleteContact](context, data) {
    return new Promise((resolve) => {
      api.deleteContact(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.deleteCounterparty](context, data) {
    return new Promise((resolve) => {
      api.deleteCounterparty(data)
        .then((response) => {
          resolve(response.data)
        })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
