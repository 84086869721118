import axios from "@/api/axios"

const module = 'orders'

const get = (filter) => {
  return axios.get(`/${module}/get`, {params: filter})
}

const getById = (id) => {
  return axios.get(`/${module}/getById/` + id)
}

const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/` + id)
}


const deleteOrder = (id) => {
  return axios.get(`/${module}/delete/` + id)
}

const addPosition = (data) => {
  return axios.post(`/${module}/addPosition`, data)
}

const getPositionsByOrderId = (params) => {
  return axios.get(`/${module}/getPositionsByOrderId`, {
    params: params,
  })
}
const deletePositionById = (params) => {
  return axios.post(`/${module}/deletePositionById`, params)
}

const updatePosition = (data) => {
  return axios.post(`/${module}/updatePosition`, data)
}

const setPositionPrice = (data) => {
  return axios.post(`/${module}/setPositionPrice`, data)
}

const updateOrder = (data) => {
  return axios.post(`/${module}/updateOrder`, data)
}


const copy = (data) => {
  return axios.post(`/${module}/copy`, data)
}

const create = (data) => {
  return axios.post(`/${module}/create`, data)
}

const setDiscount = (data) => {
  return axios.post(`${module}/setDiscount`, data)
}

const reorder = (data) => {
  return axios.post(`${module}/reorder`, data)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}

const remove = (id) => {
  return axios.delete(`/${module}/delete/` + id)
}


export default {
  get,
  getById,
  getByRequestId,
  deleteOrder,
  addPosition,
  getPositionsByOrderId,
  deletePositionById,
  updatePosition,
  setPositionPrice,
  updateOrder,
  copy,
  create,
  setDiscount,
  reorder,
  save,
  remove,
}
