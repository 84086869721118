import api from '@/api/paymentType'

const module = 'paymentTypes'

const state = {
  loading: false,
  submitting: false,
  list: null,
  errors: null,
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,
}

export const actionTypes = {
  get: `[${module}] get`,
  save: `[${module}] save`,
  remove: `[${module}] remove`,
  restore: `[${module}] restore`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.list = payload.data
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  }
}

const actions = {
  [actionTypes.get](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },
  [actionTypes.remove](context, id) {
    return new Promise((resolve) => {
      api.remove(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.restore](context, id) {
    return new Promise((resolve) => {
      api.restore(id)
        .then((response) => {
          resolve(response.data)
        })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
