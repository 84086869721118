<template>
  <div id="erp">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
export default {
  name: 'app',
  computed: {
    layout(){
      return (this.$route.meta.layout || 'auth') + '-layout'
    }
  },
  components: {
    AuthLayout, MainLayout
  },
  created() {
    document.title = this.$route.meta.title || 'GlassERP'
  }
}
</script>

