import axios from "@/api/axios"

const module = 'timeline'

const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/`+id)
}

const getByOrderId = (id) => {
  return axios.get(`/${module}/getByOrderId/`+id)
}

const changeElement = (element) => {
  return axios.post(`/${module}/changeElement`, element)
}

export default {
  getByRequestId,
  getByOrderId,
  changeElement,
}
