import axios from "@/api/axios"

const module = 'documents'

const getTemplates = () => {
  return axios.get(`/${module}/getTemplates`)
}

const saveTemplate = (data) => {
  return axios.post(`/${module}/saveTemplate`, data)
}

const removeTemplate = (id) => {
  return axios.delete(`/${module}/removeTemplate/`+id)
}

const restoreTemplate = (id) => {
  return axios.post(`/${module}/restoreTemplate/`+id)
}


export default {
  getTemplates,
  saveTemplate,
  removeTemplate,
  restoreTemplate
}
