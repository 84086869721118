import axios from "@/api/axios"

const module = 'calculations'

const get = (params) => {
  return axios.get(`/${module}/`, {params: params})
}
const getById = (params) => {
  return axios.get(`/${module}/getById`, {params: params})
}
const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/` + id)
}

const deleteCalculation = (id) => {
  return axios.get(`/${module}/delete/` + id)
}

const addPosition = (data) => {
  return axios.post(`/${module}/addPosition`, data)
}

const getPositionsByCalculationId = (params) => {
  return axios.get(`/${module}/getPositionsByCalculationId`, {
    params: params,
  })
}

const deletePositionById = (params) => {
  return axios.post(`/${module}/deletePositionById`, params)
}
const updatePosition = (data) => {
  return axios.post(`/${module}/updatePosition`, data)
}
const setPositionPrice = (data) => {
  return axios.post(`/${module}/setPositionPrice`, data)
}

const updateCalculation = (data) => {
  return axios.post(`/${module}/updateCalculation`, data)
}

const copy = (data) => {
  return axios.post(`/${module}/copy`, data)
}

const toOrder = (data) => {
  return axios.post(`/${module}/toOrder`, data)
}


const create = (data) => {
  return axios.post(`/${module}/create`, data)
}

const setDiscount = (data) => {
  return axios.post(`${module}/setDiscount`, data)
}

const save = (data) => {
  return axios.post(`${module}/save`, data)
}

const reorder = (data) => {
  return axios.post(`${module}/reorder`, data)
}

export default {
  get,
  getByRequestId,
  addPosition,
  getById,
  getPositionsByCalculationId,
  deletePositionById,
  updatePosition,
  updateCalculation,
  copy,
  toOrder,
  create,
  setPositionPrice,
  deleteCalculation,
  setDiscount,
  save,
  reorder,
}
