import axios from "@/api/axios"

const module = 'files'

const getByRequestId = (id) => {
  return axios.get(`/${module}/getByRequestId/` + id)
}

const getByOrderId = (id) => {
  return axios.get(`/${module}/getByOrderId/` + id)
}

const getByClientId = (id) => {
  return axios.get(`/${module}/getByClientId/` + id)
}

const save = (data) => {
  return axios.post(`/${module}/save`, data)
}

const remove = (id) => {
  return axios.delete(`/${module}/remove/` + id)
}

const restore = (id) => {
  return axios.post(`/${module}/restore/` + id)
}


export default {
  getByRequestId,
  getByOrderId,
  getByClientId,
  save,
  remove,
  restore,
}
