import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store/index"
import {actionTypes as authActions} from "@/store/modules/user";

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    meta: {auth: false, layout: 'auth', title: 'GlassERP - авторизация'},
    component: () => import('@/views/Auth')
  },
  {
    path: '/',
    name: 'Home',
    meta: {auth: true, layout: 'main', title: 'GlassERP - сводка'},
    component: () => import('@/views/Home')
  },
  {
    path: '/requests',
    name: 'Requests',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Обращения'},
    component: () => import('@/views/Requests')
  },
  {
    path: "/requests/:id",
    name: "Request",
    meta: {auth: true, layout: "main", title: 'GlassERP - Обращение'},
    component: () => import("@/views/Request"),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Заказы'},
    component: () => import('@/views/Orders')
  },
  {
    path: '/orders/:id',
    name: 'Order',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Заказ'},
    component: () => import('@/views/Order')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Задачи'},
    component: () => import('@/views/Tasks')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Замеры и монтаж'},
    component: () => import('@/views/Calendar')
  },
  {
    path: '/clients',
    name: 'Clients',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Клиенты'},
    component: () => import('@/views/Clients')
  },
  {
    path: '/clients/:id',
    name: 'Client',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Клиент'},
    component: () => import('@/views/Client')
  },
  {
    path: '/purchases',
    name: 'Purchases',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Закупки'},
    component: () => import('@/views/Purchases')
  },
  {
    path: '/purchases/:id',
    name: 'Purchase',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Заказ поставщику'},
    component: () => import('@/views/Purchase')
  },
  {
    path: '/assortment',
    name: 'Assortment',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Товары и услуги'},
    component: () => import('@/views/Assortment')
  },
  {
    path: '/payments',
    name: 'Payments',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Платежи'},
    component: () => import('@/views/Payments')
  },
  {
    path: '/settings',
    meta: {auth: true, layout: 'main', title: 'GlassERP - Настройки'},
    component: () => import('@/views/Settings'),
    children: [
      {
        path: '',
        name: 'Settings',
        meta: {auth: true, layout: 'main', title: 'GlassERP - Настройки'},
        component: () => import('@/views/settings/Main')
      },
      {
        path: "/settings/employees",
        name: "Employees",
        meta: {auth: true, layout: "main", title: 'GlassERP - Сотрудники'},
        component: () => import("@/views/settings/Employees"),
      },
       {
        path: "/settings/payment_types",
        name: "PaymentTypes",
        meta: {auth: true, layout: "main", title: 'GlassERP - Виды платежей'},
        component: () => import("@/views/settings/PaymentTypes"),
      },
      {
        path: "/settings/brigades",
        name: "Brigades",
        meta: {auth: true, layout: "main", title: 'GlassERP - Бригады'},
        component: () => import("@/views/settings/Brigades"),
      },
      {
        path: "/settings/offices",
        name: "Offices",
        meta: {auth: true, layout: "main", title: 'GlassERP - Офисы'},
        component: () => import("@/views/settings/Offices"),
      },
      {
        path: "/settings/storages",
        name: "Storages",
        meta: {auth: true, layout: "main", title: 'GlassERP - Склады'},
        component: () => import("@/views/settings/Storages"),
      },
      {
        path: "/settings/organizations",
        name: "Organizations",
        meta: {auth: true, layout: "main", title: 'GlassERP - Юридические лица'},
        component: () => import("@/views/settings/Organizations"),
      },
      {
        path: "/settings/suppliers",
        name: "Suppliers",
        meta: {auth: true, layout: "main", title: 'GlassERP - Поставщики'},
        component: () => import("@/views/settings/Suppliers"),
      },
      {
        path: "/settings/statuses/request",
        name: "StatusesRequests",
        meta: {auth: true, layout: "main", title: 'GlassERP - Статусы обращений'},
        component: () => import("@/views/settings/RequestStatuses"),
      },
      {
        path: "/settings/statuses/order",
        name: "StatusesOrders",
        meta: {auth: true, layout: "main", title: 'GlassERP - Статусы заказов'},
        component: () => import("@/views/settings/OrderStatuses"),
      },
      {
        path: "/settings/expenses",
        name: "Expenses",
        meta: {auth: true, layout: "main", title: 'GlassERP - Статьи расходов'},
        component: () => import("@/views/settings/Expenses"),
      },
      {
        path: "/settings/integrations",
        name: "Integrations",
        meta: {auth: true, layout: "main", title: 'GlassERP - Интеграции'},
        component: () => import("@/views/settings/Integrations"),
      },
      {
        path: "/settings/document_templates",
        name: "DocumentTemplates",
        meta: {auth: true, layout: "main", title: 'GlassERP - Шаблоны документов'},
        component: () => import("@/views/settings/DocumentTemplates"),
      },
    ]
  },
  {
    path: "/fitter/list",
    name: "FitterList",
    meta: {auth: true, layout: "main", title: 'GlassERP - Заявки на замеры и монтаж'},
    component: () => import("@/views/fitter/List"),
  },
  {
    path: "/fitter/work",
    name: "FitterWork",
    meta: {auth: true, layout: "main", title: 'GlassERP - Заявки в работе'},
    component: () => import("@/views/fitter/Work"),
  },
  {
    path: "/fitter/complete",
    name: "FitterComplete",
    meta: {auth: true, layout: "main", title: 'GlassERP - Выполненные заявки'},
    component: () => import("@/views/fitter/Complete"),
  },
  {
    path: "/fitter/event/:id",
    name: "FitterEvent",
    meta: {auth: true, layout: "main", title: 'GlassERP'},
    component: () => import("@/views/fitter/Event"),
  },
  {
    path: '*',
    name: 'Error404',
    meta: {auth: false, layout: 'auth', title: 'GlassERP - Страница не найдена'},
    component: () => import(/* webpackChunkName: "Error404" */ '@/views/Error404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth)
  if (requireAuth) {
    let currentUser = store.state.user.user
    let isLoggedIn = store.state.user.isLoggedIn
    if (requireAuth && !currentUser && !isLoggedIn) {
      store.dispatch(authActions.getUser)
        .then(() => {
          currentUser = store.state.user.user
          isLoggedIn = store.state.user.isLoggedIn
          if (requireAuth && !currentUser && !isLoggedIn) {
            next('/auth')
          } else {
            next()
          }
        })
        .catch(() => {
          next('/auth')
        })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
