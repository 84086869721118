import api from '@/api/assortment'

const module = 'assortment'

const state = {
  loading: false,
  submitting: false,
  products: [],
  groups: [],
  errors: null,
  total: 0
}

export const mutationTypes = {
  getProductsStart: `[${module}] getProductsStart`,
  getProductsSuccess: `[${module}] getProductsSuccess`,
  getProductsFailure: `[${module}] getProductsFailure`,

  saveProductStart: `[${module}] saveProductStart`,
  saveProductSuccess: `[${module}] saveProductSuccess`,
  saveProductFailure: `[${module}] saveProductFailure`,

  getGroupsStart: `[${module}] getGroupsStart`,
  getGroupsSuccess: `[${module}] getGroupsSuccess`,
  getGroupsFailure: `[${module}] getGroupsFailure`,

  saveGroupStart: `[${module}] saveGroupStart`,
  saveGroupSuccess: `[${module}] saveGroupSuccess`,
  saveGroupFailure: `[${module}] saveGroupFailure`,

  clearData: `[${module}] clearData`,
}

export const actionTypes = {
  getProducts: `[${module}] getProducts`,
  saveProduct: `[${module}] saveProduct`,
  removeProduct: `[${module}] removeProduct`,
  getGroups: `[${module}] getGroups`,
  saveGroup: `[${module}] saveGroup`,
  removeGroup: `[${module}] removeGroup`,

  removeImage: `[${module}] removeImage`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getProductsStart](state) {
    state.loading = true
  },
  [mutationTypes.getProductsSuccess](state, payload) {
    state.loading = false
    state.products = payload.data
    state.total = payload.total
  },
  [mutationTypes.getProductsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.getGroupsStart](state) {
    state.loading = true
  },
  [mutationTypes.getGroupsSuccess](state, payload) {
    state.loading = false
    state.groups = payload.data
  },
  [mutationTypes.getGroupsFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveProductStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveProductSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveProductFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },


  [mutationTypes.saveGroupStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveGroupSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveGroupFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.clearData](state) {
    state.loading = false
    state.submitting = false
    state.products = []
    state.groups = []
    state.errors = null
    state.total = 0
  },
}

const actions = {
  [actionTypes.getProducts](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getProductsStart)
      api.getProducts(params)
        .then((response) => {
          context.commit(mutationTypes.getProductsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getProductsFailure, errors)
        })
    })
  },

  [actionTypes.getGroups](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGroupsStart)
      api.getGroups(params)
        .then((response) => {
          context.commit(mutationTypes.getGroupsSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getGroupsFailure, errors)
        })
    })
  },

  [actionTypes.saveProduct](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveProductStart)
      api.saveProduct(data)
        .then((response) => {
          context.commit(mutationTypes.saveProductSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveProductFailure, errors)
        })
    })
  },

  [actionTypes.saveGroup](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveGroupStart)
      api.saveGroup(data)
        .then((response) => {
          context.commit(mutationTypes.saveGroupSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveGroupFailure, errors)
        })
    })
  },

  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clearData)
    })
  },

  [actionTypes.removeImage](context, id) {
    return new Promise((resolve) => {
      api.removeImage(id)
        .then((response) => {
          resolve(response)
        })
    })
  },

  [actionTypes.removeProduct](context, id) {
    return new Promise((resolve) => {
      api.removeProduct(id)
        .then((response) => {
          resolve(response)
        })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
