import api from '@/api/calculation'

const module = 'calculations'

const state = {
  list: [],
  current: null,
  currentLoading: false,
  currentPositions: null,
  currentPositionsLoading: false,
  loading: false,
  submitting: false,
  errors: null,
}

export const mutationTypes = {
  getStart: `[${module}] getStart`,
  getSuccess: `[${module}] getSuccess`,
  getFailure: `[${module}] getFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,

  clearCurrent: `[${module}] clearCurrent`,
  clear: `[${module}] clear`,
  clearPositions: `[${module}] clearPositions`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  getCurrentPositionsStart: `[${module}] getCurrentPositionsStart`,
  getCurrentPositionsSuccess: `[${module}] getCurrentPositionsSuccess`,
  getCurrentPositionsFailure: `[${module}] getCurrentPositionsFailure`,
}

export const actionTypes = {
  getByRequestId: `[${module}] getByRequestId`,
  get: `[${module}] get`,
  save: `[${module}] save`,
  create: `[${module}] create`,
  delete: `[${module}] delete`,
  getCurrent: `[${module}] getCurrent`,
  updateCurrent: `[${module}] updateCurrent`,
  clearCurrent: `[${module}] clearCurrent`,
  addPosition: `[${module}] addPosition`,
  getPositions: `[${module}] getPositions`,
  updatePosition: `[${module}] updatePosition`,
  deletePosition: `[${module}] deletePosition`,
  clearPositions: `[${module}] clearPositions`,
  copy: `[${module}] copy`,
  toOrder: `[${module}] toOrder`,
  setPositionPrice: `[${module}] setPositionPrice`,
  clear: `[${module}] clear`,
  setDiscount: `[${module}] setDiscount`,
  reorder: `[${module}] reorder`,
}

const mutations = {
  [mutationTypes.getStart](state) {
    state.loading = true
  },
  [mutationTypes.getSuccess](state, payload) {
    state.loading = false
    state.list = payload
  },
  [mutationTypes.getFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.clearCurrent](state) {
    state.current = null
  },

  [mutationTypes.clearPositions](state) {
    state.currentPositions = null
  },

  [mutationTypes.getCurrentStart](state) {
    state.currentLoading = true;
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.current = payload;
    state.currentLoading = false;
  },
  [mutationTypes.getCurrentFailure](state) {
    state.currentLoading = false;
  },

  [mutationTypes.getCurrentPositionsStart](state) {
    state.currentPositionsLoading = true;
  },
  [mutationTypes.getCurrentPositionsSuccess](state, payload) {
    state.currentPositions = payload;
    state.currentPositionsLoading = false;
  },
  [mutationTypes.getCurrentPositionsFailure](state) {
    state.currentPositionsLoading = false;
  },

  [mutationTypes.clear](state) {
    state.list = []
    state.current = null
    state.currentLoading = false
    state.currentPositions = null
    state.currentPositionsLoading = false
    state.loading = false
    state.submitting = false
    state.errors = null
  }
}

const actions = {
  [actionTypes.getByRequestId](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.delete](context, params) {
    return new Promise((resolve) => {
      api.deleteCalculation(params)
        .then((response) => {
          resolve(response.data)
        })
    })
  },

  [actionTypes.get](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getStart)
      api.getByRequestId(params)
        .then((response) => {
          context.commit(mutationTypes.getSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFailure, errors)
        })
    })
  },

  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },

  [actionTypes.getCurrent](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getById(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          context.commit(mutationTypes.getCurrentFailure)
        })
    })
  },

  [actionTypes.updateCurrent](context, data) {
    return new Promise((resolve) => {
      api.updateCalculation(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          // Обработка ошибок
        })
    })
  },

  [actionTypes.clearCurrent](context) {
    return new Promise(() => {
      context.commit(mutationTypes.clearCurrent)
    })
  },

  [actionTypes.addPosition](context, data) {
    return new Promise((resolve) => {
      api.addPosition(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {

        })
    })
  },

  [actionTypes.getPositions](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentPositionsStart)
      api.getPositionsByCalculationId(params)
        .then((response) => {
          context.commit(mutationTypes.getCurrentPositionsSuccess, response.data)
          resolve(response.data)
        })
        .catch(() => {
          context.commit(mutationTypes.getCurrentPositionsFailure)
        })
    })
  },

  [actionTypes.updatePosition](context, data) {
    return new Promise((resolve) => {
      api.updatePosition(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.deletePosition](context, params) {
    return new Promise((resolve) => {
      api.deletePositionById(params).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.clearPositions](context) {
    return new Promise(() => {
      context.commit(mutationTypes.clearPositions)
    })
  },

  [actionTypes.copy](context, data) {
    return new Promise((resolve) => {
      api.copy(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.toOrder](context, data) {
    return new Promise((resolve) => {
      api.toOrder(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.create](context, data) {
    return new Promise((resolve) => {
      api.create(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.setPositionPrice](context, data) {
    return new Promise((resolve) => {
      api.setPositionPrice(data).then((response) => {
        resolve(response.data)
      })
    })
  },

  [actionTypes.clear](context) {
    return new Promise(() => {
      context.commit(mutationTypes.clear)
    })
  },
  [actionTypes.setDiscount](context, data) {
    return new Promise((resolve) => {
      api.setDiscount(data).then((response) => {
        resolve(response.data)
      })
    })
  },
  [actionTypes.reorder](context, data) {
    return new Promise((resolve) => {
      api.reorder(data).then((response) => {
        resolve(response.data)
      })
    })
  },
}

export default {
  state,
  mutations,
  actions
}
