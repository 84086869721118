import api from '@/api/calendar'

const module = 'calendar'

const state = {
  loading: false,
  submitting: false,
  list: [],
  filteredList: [],
  size: 0,
  current: null,
  errors: null,
}

export const mutationTypes = {
  getAllStart: `[${module}] getAllStart`,
  getAllSuccess: `[${module}] getAllSuccess`,
  getAllFailure: `[${module}] getAllFailure`,

  getFilteredStart: `[${module}] getFilteredStart`,
  getFilteredSuccess: `[${module}] getFilteredSuccess`,
  getFilteredFailure: `[${module}] getFilteredFailure`,

  getCurrentStart: `[${module}] getCurrentStart`,
  getCurrentSuccess: `[${module}] getCurrentSuccess`,
  getCurrentFailure: `[${module}] getCurrentFailure`,

  saveStart: `[${module}] saveStart`,
  saveSuccess: `[${module}] saveSuccess`,
  saveFailure: `[${module}] saveFailure`,

  clear: `[${module}] clear`,
}

export const actionTypes = {
  getAll: `[${module}] getAll`,
  getFiltered: `[${module}] getFiltered`,
  getFitterList: `[${module}] getFitterList`,
  getById: `[${module}] getById`,
  changeStatus: `[${module}] changeStatus`,
  save: `[${module}] save`,
  create: `[${module}] create`,
  remove: `[${module}] remove`,
  restore: `[${module}] restore`,
  clear: `[${module}] clear`,
}

const mutations = {
  [mutationTypes.getAllStart](state) {
    state.loading = true
  },
  [mutationTypes.getAllSuccess](state, payload) {
    state.loading = false
    state.list = payload
  },
  [mutationTypes.getAllFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },
  [mutationTypes.getFilteredStart](state) {
    state.loading = true
  },
  [mutationTypes.getFilteredSuccess](state, payload) {
    state.loading = false
    state.filteredList = payload.data
  },
  [mutationTypes.getFilteredFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.getCurrentStart](state) {
    state.loading = true
  },
  [mutationTypes.getCurrentSuccess](state, payload) {
    state.loading = false
    state.current = payload
  },
  [mutationTypes.getCurrentFailure](state, payload) {
    state.loading = false
    state.errors = payload
  },

  [mutationTypes.saveStart](state) {
    state.submitting = true
  },
  [mutationTypes.saveSuccess](state, payload) {
    state.submitting = false
  },
  [mutationTypes.saveFailure](state, payload) {
    state.submitting = false
    state.errors = payload
  },

  [mutationTypes.clear](state) {
    state.loading = false
    state.submitting = false
    state.list = []
    state.filteredList = []
    state.size = 0
    state.current = null
    state.errors = null
  },
}

const actions = {
  [actionTypes.getAll](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllStart)
      api.get(params)
        .then((response) => {
          context.commit(mutationTypes.getAllSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getAllFailure, errors)
        })
    })
  },
  [actionTypes.getFiltered](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getFilteredStart)
      api.getFiltered(params)
        .then((response) => {
          context.commit(mutationTypes.getFilteredSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFilteredFailure, errors)
        })
    })
  },
  [actionTypes.save](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.save(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },

  [actionTypes.create](context, data) {
    return new Promise((resolve) => {
      api.create(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((errors) => {

        })
    })
  },

  [actionTypes.changeStatus](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.saveStart)
      api.changeStatus(data)
        .then((response) => {
          context.commit(mutationTypes.saveSuccess)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.saveFailure, errors)
        })
    })
  },

  [actionTypes.getFitterList](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getFilteredStart)
      api.getFitterList(data)
        .then((response) => {
          context.commit(mutationTypes.getFilteredSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getFilteredFailure, errors)
        })
    })
  },
  [actionTypes.getById](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentStart)
      api.getById(id)
        .then((response) => {
          context.commit(mutationTypes.getCurrentSuccess, response.data)
          resolve(response.data)
        })
        .catch((errors) => {
          context.commit(mutationTypes.getCurrentFailure, errors)
        })
    })
  },
  [actionTypes.remove](context, params) {
    return new Promise((resolve) => {
      api.remove(params)
    })
  },
  [actionTypes.restore](context, params) {
    return new Promise((resolve) => {
      api.restore(params)
    })
  },
  [actionTypes.clear](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.clear)
    })
  },
}

export default {
  state,
  mutations,
  actions
}
